import MonetButton from "components/tailwind/MonetButton";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import StatusBadge from "components/tailwind/StatusBadge";
import { useSessionProvider } from "contexts/SessionProvider";

const Billing = () => {
  const { partner } = useSessionProvider();

  const stripeCustomerPortalUrl = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL;
  const env = process.env.REACT_APP_ENV_NAME;

  const handleClick = () => {
    window.open(stripeCustomerPortalUrl, "_blank");
  };

  if (env === "sandbox" || !partner?.subscription?.status) return null;

  return ["ACTIVE", "TRIALING"].includes(partner?.subscription?.status) ? (
    <MonetCard>
      <MonetCardTitle>
        <div className="flex gap-2 ">
          Subscription
          <StatusBadge type="subscription" text={partner?.subscription?.status} />
        </div>
      </MonetCardTitle>
      <MonetButton className="w-fit" onClick={handleClick}>
        Manage your subscription
      </MonetButton>
    </MonetCard>
  ) : (
    <MonetCard>
      <p className="text-sm">Your subscription has been cancelled. Please contact MONET at support@monet.money to re-activate your subscription</p>
    </MonetCard>
  );
};

export default Billing;
